import React, { useState } from 'react';
import Container from '@material-ui/core/Container';

import * as Yup from 'yup';
import { guid } from 'config/config';
import { localStorageData } from 'services/auth/localStorageData';
import { useMutation } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import { toast } from 'react-toastify';
import history from '../.././history';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorService from 'services/formatError/ErrorService';

import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { smallbusiness } from 'config/config';
import axios from 'axios';

function BusinessDetails() {
  const [isLoading, setisLoading] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const dispatch = useDispatch();
  const { mutate } = useMutation(
    (updateprofile) =>
      userServices.commonPostService('/createseller', updateprofile),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setisLoading(false);
        toast.success('Business Info Added');

        dispatch({
          type: 'ChangeCurrentRole',
          payload: smallbusiness,
        });
        history.push('/smallbusiness');
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      businessName: '',
      abnNumber: '',
      story: '',
    },
    validationSchema: Yup.object().shape({
      abnNumber: Yup.string()
        .min(11, 'Must be 11 digit ABN Number')
        .max(11, 'Must be 11 digit ABN Number')
        .required('Required'),

      businessName: Yup.string().required('Required'),
      story: Yup.string().max(600, 'No more than 600 characters'),
    }),
    onSubmit: async (values) => {
      setisLoading(true);

      axios
        .get(
          `https://abr.business.gov.au/json/AbnDetails.aspx?abn=${formik.values.abnNumber}&callback=r&guid=${guid}`
        )
        .then((res) => {
          var result = JSON.parse(res.data.substring(2, res.data.length - 1));

          if (result.AbnStatus != 'Active') {
            toast.error('Invalid ABN Number');
          } else {
            values.id = localStorageData('_id');
            console.log(values);
            mutate(values);
          }
        });
    },
  });

  return (
    <div>
      <Container maxWidth='sm'>
        <form
          className=' relative  mt-4 mb-3 mt-20'
          onSubmit={formik.handleSubmit}
        >
          <div className='center-styl'>Business Details</div>

          <div className='  content-center my-4 items-center justify-center'>
            <label className='w-40'>Businessname:</label>
            <input
              type='text'
              name='businessName'
              id='businessName'
              value={formik.values.businessName}
              onChange={(e) =>
                formik.setFieldValue('businessName', e.target.value)
              }
              className='input-styl mx-2 h-12 px-2 py-2 w-20 mt-2'
              placeholder='BusinessName'
            />

            {formik.touched.businessName && formik.errors.businessName ? (
              <div className='text-red-600 text-xs'>
                {formik.errors.businessName}
              </div>
            ) : null}
          </div>

          <div className=' my-4 content-center my-4 items-center justify-center'>
            <label className='w-40'>ABN Number:</label>
            <input
              type='text'
              name='abnNumber'
              id='abnNumber'
              value={formik.values.abnNumber}
              onChange={(e) =>
                formik.setFieldValue('abnNumber', e.target.value)
              }
              className='input-styl mx-2 h-12 px-2 py-2 w-20 mt-2'
              placeholder='ABN Number'
            />
            {formik.touched.abnNumber && formik.errors.abnNumber ? (
              <div className='text-red-600 text-xs'>
                {formik.errors.abnNumber}
              </div>
            ) : null}
          </div>

          <div className=' my-4 content-center my-4 items-center justify-center'>
            <label className='w-40'>Your Story</label>
            <textarea
              type='text'
              rows='6'
              cols='50'
              name='story'
              id='story'
              value={formik.values.story}
              onChange={(e) => {
                formik.setFieldValue('story', e.target.value);
                setCharacterCount(e.target.value.length);
              }}
              className='input-styl mx-2 px-2 py-2 w-20 mt-2'
              placeholder='*What is the purpose of your business?
              *Who does your business represent?
              *who is your target audience and how do your products
              meet their values and believes?
              *why should your target audience buy from you?'
            ></textarea>
            <p className='float-right'>{characterCount} words</p>
            {formik.touched.story && formik.errors.story ? (
              <div className='text-red-600 text-xs'>{formik.errors.story}</div>
            ) : null}
          </div>

          <div className='flex mt-10 justify-end content-end'>
            {isLoading ? (
              <>
                <CircularProgress />
              </>
            ) : (
              <button
                className='btn-styl h-10'
                type='submit'
                //  onClick={savedetails}
              >
                Save
              </button>
            )}
          </div>
        </form>
        <div className='flex mt-4 justify-end content-end'>
          <button
            className='text-blue-600 underline font-bold h-10'
            type='submit'
            onClick={() => {
              history.push(`/approvebusiness`);
            }}
          >
            Request to become a Seller
          </button>
        </div>
      </Container>
    </div>
  );
}

export default BusinessDetails;
