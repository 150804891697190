import React, { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { applyForForgetPass } from '../actions/userAction/userActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import userServices from '../../services/httpService/userAuth/userServices';

import ErrorService from '../../services/formatError/ErrorService';

function UpdatePassword() {
  const [successData, setsuccessData] = useState('');
  const [errorMsg, seterrorMsg] = useState('');

  const {
    isError,

    isLoading,

    isSuccess,
    mutate,
  } = useMutation((updatePass) => userServices.updatePass(updatePass), {
    onError: (error) => {
      seterrorMsg(ErrorService.uniformError(error));
    },
    onSuccess: (data) => {
      setsuccessData(data.data);
    },
  });
  const params = useParams();

  // const [success, setsuccess] = useState("")
  // // const [isError, setisError] = useState()
  // const [isLoading, seisLoading] = useState(false)
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      pass: '',
      cpass: '',
    },
    validationSchema: Yup.object().shape({
      pass: Yup.string()
        .min(8, 'Must be more than 8 characters')
        .required('Required'),
      cpass: Yup.string().oneOf(
        [Yup.ref('pass'), null],
        'Passwords must match'
      ),
    }),
    onSubmit: async (values) => {
      console.log(params);
      values.email = decodeURIComponent(params.email);
      values.uniqueId = params.id;
      mutate(values);

      ///// alert(values.email)

      // applyForForgetPass(values)
      //     .then(data => {
      //         ////  alert("succfully login")
      //         alert(data)
      //         //// setsuccess(true)

      //     })
      //     .catch(err => {
      //         alert(err)
      //     });
      // seisLoading(true)
      // userServices.applyForForgetPass(values).then(
      //     (response) => {

      //         setisError("")
      //         setsuccess(response.data)
      //         seisLoading(false)

      //     },
      //     (error) => {

      //         //alert(error.message)
      //         setsuccess("")
      //         setisError(error.response.data.message)
      //         seisLoading(false)
      //     }
      // );
    },
  });

  return (
    <div>
      <Container>
        <form
          className=' relative w-1/2 mt-4 mb-3 mt-16'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-xl antialiased  font-bold items-center'>
            Set your New Password
          </div>

          <div className='my-10'>
            <label
              className='block uppercase text-gray-700 text-xs font-bold mb-2'
              htmlFor='Name'
            >
              Password
            </label>
            <input
              type='password'
              name='pass'
              id='pass'
              className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-2 border-gray-600 rounded-sm'
              placeholder='Password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pass}
            />
            {formik.touched.pass && formik.errors.pass ? (
              <div className='text-red-600'>{formik.errors.pass}</div>
            ) : null}
          </div>

          <div className='my-10'>
            <label
              className='block uppercase text-gray-700 text-xs font-bold mb-2'
              htmlFor='Name'
            >
              Confirm Password
            </label>
            <input
              type='password'
              name='cpass'
              id='cpass'
              className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-2 border-gray-600 rounded-sm'
              placeholder='Confirm Password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cpass}
            />
            {formik.touched.cpass && formik.errors.cpass ? (
              <div className='text-red-600'>{formik.errors.cpass}</div>
            ) : null}
          </div>

          <div className='my-10'>
            <div className='flex flex-row  justify-items-center items-center justify-center'>
              <div className='mt-2 mx-8'>
                {isLoading == true ? (
                  <CircularProgress />
                ) : (
                  <button
                    className='bg-white  uppercase orgcolor font-bold shadow-2xl btn-shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150'
                    type='submit'
                  >
                    Submit
                  </button>
                )}
              </div>
              <div className='mt-2 mx-8'>
                {/* <button
                className='bg-white  uppercase orgcolor font-bold shadow-2xl btn-shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150'
                type='button'
              >
                Sign In
              </button> */}
              </div>
            </div>
          </div>
        </form>
        {/* {isError && <Alert severity="error">{isError}</Alert>} */}
        {isError && <Alert severity='error'>{errorMsg}</Alert>}
        {isSuccess && <Alert severity='success'> {successData}</Alert>}
      </Container>
    </div>
  );
}

export default UpdatePassword;
