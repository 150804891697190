import React from 'react';
import { toast } from 'react-toastify';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import { useMutation } from 'react-query';
import ErrorService from 'services/formatError/ErrorService';
import userServices from 'services/httpService/userAuth/userServices';

import DeleteIcon from '@material-ui/icons/Delete';
function Toogle(props) {
  const [state, setState] = React.useState({
    checkedA: props.data.isActive,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });

    let data = {
      Id: props.data._id,
      status: event.target.checked,
    };

    ChangeStatus.mutate(data);
    //// alert(event.target.checked);

    ////alert(props.data._id);
  };

  const handleDelete = () => {
    let data = {
      Id: props.data._id,
    };
    DeleteDeal.mutate(data);

    /////alert(props.data._id);
  };

  const ChangeStatus = useMutation(
    (makeactive) =>
      userServices.commonPostService('/changedealstatus', makeactive),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        if (state.checkedA === true) {
          toast.success('Listing deactivated');
        } else {
          toast.success('Listing activated');
        }
      },
    }
  );

  const DeleteDeal = useMutation(
    (deletedeal) => userServices.commonPostService('/deletedeal', deletedeal),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success('Deal has been deleted');

        // window.location = '/';
      },
    }
  );

  return (
    <div className='flex float-right'>
      <Switch
        checked={state.checkedA}
        onChange={handleChange}
        name='checkedA'
        // id={s._id}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <IconButton aria-label='delete'>
        <DeleteIcon onClick={handleDelete} fontSize='small' />
      </IconButton>
    </div>
  );
}

export default Toogle;
