import React, { useEffect } from "react";
import history from "./../../history";
import Header from "components/Header";
import Category from "components/Data/index";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Divider from "@material-ui/core/Divider";
import ReactGA from "react-ga";
import Skelton from "components/Card/Skelton";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { filterProductBycategory } from "globalFunctions/filterProductBycategory";
import { localStorageData } from "services/auth/localStorageData";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { fetchdeals } from "client/actions/userAction/dealsAction";

function track() {
  ReactGA.initialize("UA-169360201-1");

  ReactGA.pageview("/");
}
function LandingPage() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);

  const { isLoading, data } = useSelector((state) => state.deals);

  console.log("new=>", data);

  useEffect(() => {
    track();
    dispatch(fetchdeals());
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {localStorageData("_id") ? (
        <a
          onClick={() => history.push(`/approvebusiness`)}
          className="flex center-styl underline pt-6 text-color-gray cursor-pointer"
        >
          Start selling today!!
        </a>
      ) : (
        <a
          onClick={() => history.push(`/signup`)}
          className="flex center-styl pt-6 underline text-color-gray cursor-pointer"
        >
          Start selling today!!
        </a>
      )}

      <Header />
      <Container>
        {isLoading == false ? (
          <>
            {Category.map((s, i) => (
              <div className="my-2" key={i}>
                <a
                  onClick={() => history.push(`/categorypage/${s}`)}
                  className="font-bold	text-xl "
                >
                  {s} &nbsp;
                </a>
                <Divider />
                <div className="row1">{filterProductBycategory(s, data)}</div>
              </div>
            ))}
          </>
        ) : (
          <>
            <Skelton />
            <Skelton />
            <Skelton />
            <Skelton />
          </>
        )}
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <a onClick={handleClose} className="cursor-pointer flex justify-end">
            <i className="fas fa-times"></i>
          </a>
          <br></br>
          <h2 className="text-center font-bold my-4 text-black text-xl">
            Welcome to ouiiwe
          </h2>
          <br></br>
          <br></br>
          <p className="text-center text-gray-700">
            We are uniting more small businesses in one place where they can
            easily and cheaply sell their products or services, making it easier
            for shoppers to discover and shop with them.
          </p>
          <br></br>
          <br></br>
          <p className="text-center font-semibold mt-6">
            Let’s show small businesses some love!!
          </p>
          <br></br>
        </DialogContent>
      </Dialog>
      <button
        className="fixed bottom-10 right-10 underline w-18 h-18 p-4"
        onClick={() => history.push("/review")}
      >
        Reviews
      </button>
    </div>
  );
}

export default LandingPage;
