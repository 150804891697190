import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "assets/css/App.css";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import LandingPage from "client/pages/LandingPage";
import Test from "client/test";
import Aboutus from "client/pages/Statics/Aboutus";
import Shopper from "client/pages/Shopper";
import SmallBusiness from "client/pages/SmallBusiness";

import BusinessDetails from "client/pages/BusinessDetails";

import Signup from "client/pages/Signup";
import Signin from "client/pages/Signin";
import ForgetPassword from "client/pages/ForgetPassword";

import UpdatePassword from "client/pages/UpdatePassword";
import SellDeals from "client/pages/SellDeals";
import Cart from "client/pages/Cart";
import OrderDetail from "client/pages/OrderDetail";
import DetailsEdit from "client/pages/DetailsEdit";
import ContactAdmin from "client/pages/ContactAdmin";
import NotFound from "client/pages/Statics/NotFound";
import AddCard from "client/pages/AddCard";
import Payment from "client/pages/Payment";
import ProductDetails from "client/pages/ProductDetails";

import Categorypage from "client/pages/Categorypage";
import Routes from "Admin/routes";
import Login from "Admin/Login";

import ShipmentDetails from "client/pages/ShipmentDetails";

import OrdersShopper from "client/pages/OrdersShopper";

import OrderSmallbusiness from "client/pages/OrderSmallbusiness";

import VisitProfile from "client/pages/VisitProfile";
import SearchedResult from "client/pages/SearchedResult";
import DealofDay from "client/pages/DealofDay";
import Termcondition from "client/pages/Statics/Termcondition";
import Howitwork from "client/pages/Statics/Howitwork";
import Privacypolicy from "client/pages/Statics/Privacypolicy";
import UserSearch from "client/pages/UserSearch";
import ApproveBusiness from "client/pages/Statics/ApproveBusiness";
import Display from "client/pages/Display";
import ReviewPage from "client/pages/ReviewPage";
import ReviewSmallBusiness from "client/pages/SmallBusiness/ReviewSmallBusiness";
import ShopperView from "client/pages/Shopper/ShopperView";
import SellServices from "client/pages/Services/SellServices";
import GTM from "./GTM";

function App() {
  return (
    <div style={{ flex: 1 }}>
      <GTM />
      <Navbar />
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/aboutus" exact component={Aboutus} />
        <Route path="/shopper" exact component={Shopper} />

        <Route path="/businessdetail" exact component={BusinessDetails} />
        <Route path="/approvebusiness" exact component={ApproveBusiness} />

        <Route path="/smallBusiness" exact component={SmallBusiness} />
        <Route path="/dealofday" exact component={DealofDay} />
        <Route path="/orderdetail" exact component={OrderDetail} />
        <Route path="/contactadmin" exact component={ContactAdmin} />
        <Route path="/detailsedit" exact component={DetailsEdit} />
        <Route path="/sell" exact component={SellDeals} />

        <Route path="/searchedResult" exact component={SearchedResult} />

        <Route
          path="/ordersmallbusiness"
          exact
          component={OrderSmallbusiness}
        />

        <Route path="/ordersshopper" exact component={OrdersShopper} />
        <Route path="/cart" exact component={Cart} />
        <Route path="/signup" exact component={Signup} />
        <Route path="/signin" exact component={Signin} />

        <Route path="/shipmentdetails" exact component={ShipmentDetails} />

        <Route path="/categorypage/:name" exact component={Categorypage} />
        <Route path="/visitprofile/:id" exact component={VisitProfile} />

        <Route path="/forgetpass" exact component={ForgetPassword} />
        <Route
          path="/details/:id/:productname/:shopname"
          exact
          component={ProductDetails}
        />
        <Route path="/payment/:shopId" exact component={Payment} />
        <Route path="/addcard" exact component={AddCard} />
        <Route path="/usersearch" exact component={UserSearch} />

        <Route path="/test" exact component={Test} />
        <Route path="/updatepass/:email/:id" exact component={UpdatePassword} />
        <Route path="/sell/:uploder" exact component={SellDeals} />
        <Route path="/display" exact component={Display} />
        <Route path="/testsellservices" exact component={SellServices} />

        <Route path="/termcondition" exact component={Termcondition} />
        <Route path="/howitworks" exact component={Howitwork} />
        <Route path="/privacypolicy" exact component={Privacypolicy} />
        <Route path="/login" exact component={Login} />
        <Route path="/review" exact component={ReviewPage} />
        <Route path="/reviewbusiness" exact component={ReviewSmallBusiness} />
        <Route path="/shopperview/:id" exact component={ShopperView} />

        {/* services */}

        <Route path="/sellservices" exact component={SellServices} />

        {/* Admin */}
        <Route path="/admin" component={Routes} />
        <Route path="*" exact component={NotFound} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
