import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { localStorageData } from 'services/auth/localStorageData';
import Collapse from '@material-ui/core/Collapse';
import { Slide } from 'react-slideshow-image';
import Options from './Options';
import Toogle from './Toogle';

import SeemoreCard from './SeemoreCard';
import { s3BucketUrl, smallbusiness } from '../../config/config';
import history from './../../history';
function Maincard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const getDeal = (uplodertype, productUrl, id) => {
    if (uplodertype == smallbusiness || uplodertype == 'services') {
      // setExpanded(!expanded);
      setExpanded({
        ...expanded,
        [id]: !expanded[id],
      });
    } else {
      window.open(productUrl);
    }
  };

  return (
    <>
      {props.cardData.map((s, i) => (
        <div className='card1'>
          <Grid>
            <Grid item lg={2} md={3} sm={5} xs={6}>
              <Paper elevation={0} style={{ backgroundColor: '#ffffff' }}>
                <div className='rounded-md  my-4 overflow-hidden w-72'>
                  <Slide
                    onClick={() => {
                      history.push(
                        `/details/${s._id}/${s.productName
                          .split('/')
                          .join('')}/${s.shopName.split('/').join('')}`
                      );
                    }}
                    indicators={true}
                    canSwipe={props.swipe}
                    arrows={false}
                    autoplay={false}
                    Easing='ease-in'
                  >
                    {s.pics.map((s3) => (
                      <div className='each-fade'>
                        <div className='image-container'>
                          <img
                            src={s3BucketUrl + s3}
                            alt='Loading'
                            className=' h-80 w-full object-contain'
                          />
                        </div>
                      </div>
                    ))}
                  </Slide>

                  <a
                    onClick={() => getDeal(s.uplodertype, s.productUrl, s._id)}
                    className='px-3 py-3'
                  >
                    <h5 className='text-black text-center mb-2 text-sm subpixel-antialiased truncate '>
                      {s.productName}
                    </h5>
                    <div className='flex justify-between  mx-16'>
                      <div className='text-gray-600 text-sm font-semibold '>
                        ${s.nowPrice}
                      </div>

                      {s.wasPrice ? (
                        <p className=' font-semibold line-through redcolor  text-sm '>
                          <span className='font-semibold '> ${s.wasPrice}</span>
                        </p>
                      ) : null}
                    </div>

                    {props.isShow ? (
                      <div className=' dealbtn-styl  center-styl'>
                        <button
                          className='sell-btn -mt-2'
                          type='button'
                          onClick={() =>
                            getDeal(s.uplodertype, s.productUrl, s._id)
                          }
                        >
                          {s.uplodertype == smallbusiness ? (
                            <>Buy Now</>
                          ) : s.uplodertype == 'services' ? (
                            <>Book Now</>
                          ) : (
                            <>View</>
                          )}
                        </button>
                      </div>
                    ) : null}
                  </a>
                  {/* <span>{s._id}</span> */}
                  <Collapse in={expanded[s._id]} timeout='auto' unmountOnExit>
                    <Options data={s} />
                  </Collapse>
                  <div className='px-3'>
                    {/* <span className=''>
                      <ThumbUpIcon style={{ color: 'black' }} />
                      &nbsp;
                      <span>{Math.floor(Math.random() * 50) + 1}</span>{' '}
                      &nbsp;&nbsp;
                      <QuestionAnswerIcon style={{ color: 'black' }} />
                      &nbsp;
                      <span>0</span>
                    </span> */}

                    {s.users._id === localStorageData('_id') ? (
                      <>
                        <Toogle data={s} />
                      </>
                    ) : (
                      <>
                        {/* <a className='float-right'>{s.shopName}</a> */}

                        {history.location.pathname == '/shopper' ||
                        history.location.pathname == '/smallbusiness' ? (
                          <Toogle data={s} />
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      ))}

      {props.seemore ? <SeemoreCard category={props.category} /> : null}
    </>
  );
}

export default Maincard;
