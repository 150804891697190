import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import history from "../../history";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import userServices from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";

import {
  localStorageData,
  updateLocalstoragepic,
} from "services/auth/localStorageData";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 40,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function ProfileHeader(props) {
  const classes = useStyles();
  const [follow, setFollow] = React.useState("00");

  const [following, setFollowing] = React.useState("00");
  // alert(following);

  const { mutate } = useMutation(
    (updateprofile) =>
      userServices.commonPostService("/profilepicupdate", updateprofile),
    {
      onError: (error) => {
        ///// seterrorMsg(ErrorService.uniformError(error));
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        ////  alert(s3BucketUrl + data.data.data);

        updateLocalstoragepic(data.data.data);

        toast.success("Profile has been updated");
      },
    }
  );

  const mutatefollow = useMutation(
    (followuser) => userServices.commonPostService("/followuser", followuser),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setFollow(parseInt(follow, 10) + 1);

        toast.success("Follow Successfully");
      },
    }
  );
  // eslint-disable-next-line
  const Followers = useQuery(
    "FollowersData",
    () =>
      userServices.commonGetService(
        `/getFollower/${
          props.vistorid === undefined
            ? localStorageData("_id")
            : props.vistorid
        }`
      ),
    {
      onError: (error) => {},
      onSuccess: (data) => {
        var formattedNumber = ("0" + data.data.length).slice(-2);

        setFollow(formattedNumber);
        // alert(data.data);
      },
    }
  );
  // eslint-disable-next-line
  const Following = useQuery(
    "FollowingData",
    () =>
      userServices.commonGetService(
        `/getFollowing/${
          props.vistorid === undefined
            ? localStorageData("_id")
            : props.vistorid
        }`
      ),
    {
      onError: (error) => {},
      onSuccess: (data) => {
        var formattedNumber = ("0" + data.data.Fetch.length).slice(-2);
        console.log("folow=------------>", data.data.Fetch);
        setFollowing(formattedNumber);
        // alert(formattedNumber);
      },
    }
  );

  const onChangeFollow = async (e) => {
    if (localStorageData("_id") === null) {
      toast.error("Sign In First");
    } else {
      let data = {
        uploderId: localStorageData("_id"),
        goestoId: props.vistorid,
      };

      mutatefollow.mutate(data);
    }
  };

  const onChangeHandler = async (e) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById("output");
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
      formData.append("pics", e.target.files[0]);

      formData.append("userId", localStorageData("_id"));

      mutate(formData);
    }
  };

  return (
    <div className="bg-color-bg pt-6">
      <Container maxWidth="sm">
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className=" grid mt-4">
              <center>
                <img
                  id="output"
                  className="h-24 w-24 rounded-full img-mbstyl"
                  src={props.img}
                  alt="profile"
                />
              </center>
              <div className="center-styl">
                {props.upload ? (
                  <label
                    className="text-color-gray  text-3xl "
                    htmlFor="contained-button-file"
                  >
                    +
                    <input
                      accept="image/*"
                      onChange={onChangeHandler}
                      id="contained-button-file"
                      // className='btn-styl'
                      type="file"
                    />
                  </label>
                ) : null}

                <div className=" mt-4 text-sm text-color-gray flex content-center  items-center justify-center">
                  {props.name}
                </div>
              </div>
            </div>
          </Grid>

          <div className="text-sm text-color-gray absolute mt-20 rotate">
            op: 00
          </div>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <div className=" flex my-2 text-color-gray flex-col content-center  items-center justify-center">
              <p className="text-color-gray text-sm">
                Followers
                {props.follow ? (
                  localStorageData("_id") !== props.vistorid ? (
                    <a
                      className="text-color-gray text-xl mx-2 mb-4 cursor-pointer	"
                      href={() => false}
                      onClick={() => onChangeFollow()}
                    >
                      +
                    </a>
                  ) : null
                ) : null}
              </p>
              <div className="text-color-gray text-sm">{follow}</div>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={6}>
            <div className=" flex my-2 text-color-gray flex-col content-center  items-center justify-center">
              <a className=" text-sm "> Following</a>
              <div className=" text-sm">{following}</div>
            </div>
          </Grid>

          {props.search ? (
            <Grid item lg={12} md={10} sm={10} xs={12}>
              <div>
                <Paper component="form" className={classes.root}>
                  <InputBase
                    className={classes.input}
                    placeholder="Search business name / username "
                    inputProps={{ "aria-label": "search google maps" }}
                    onClick={() => history.push(`/usersearch`)}
                  />
                  <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </div>
  );
}

export default ProfileHeader;
