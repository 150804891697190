import React from 'react';

import history from './../../history';
function SeemoreCard(props) {
  return (
    <div className='card1'>
      <div className='rounded-md border-4 border-light-blue-500 border-opacity-100 overflow-hidden h-48 mt-4 height-see py-52'>
        <button
          className='p-20 mt-20 font-semibold text-red-500'
          onClick={() => history.push(`/categorypage/${props.category}`)}
        >
          see more
        </button>
      </div>
    </div>
  );
}

export default SeemoreCard;
