import React from 'react';
// import Sidebar from '../../components/Sidebar/index';
// import logo from 'https://i.ibb.co/wrjRNP3/logo.png';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LocalMallIcon from '@material-ui/icons/LocalMall';

import Badge from '@material-ui/core/Badge';
import ListIcon from '@material-ui/icons/List';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { toast } from 'react-toastify';
import history from '../../history';
import { useDispatch, useSelector } from 'react-redux';
import { shopper, smallbusiness } from 'config/config';

import Menu from '@material-ui/core/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import MenuItem from '@material-ui/core/MenuItem';
import { ChangeUserRoles } from 'client/actions/userAction/userActions';
import PersonIcon from '@material-ui/icons/Person';
import { localStorageData, Logout } from 'services/auth/localStorageData';
function CartIcon() {
  const { userCart } = useSelector((state) => state.Cart);

  return (
    <IconButton
      aria-label='upload picture'
      component='span'
      href='/cart'
      onClick={() => history.push('/cart')}
    >
      <Badge badgeContent={userCart.length} color='secondary'>
        <LocalMallIcon style={{ color: 'white' }} />
      </Badge>
    </IconButton>
  );
}

function Navbar() {
  const logo = 'https://i.ibb.co/nk3BMmL/1.png';

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const { currentRole } = useSelector((state) => state.userRole);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorPro, setAnchorPro] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickPro = (event) => {
    setAnchorPro(event.currentTarget);
  };

  const handleClose = () => {
    // alert('sda');
    setAnchorEl(null);
    setAnchorPro(null);
    toggleDrawer('left', false);
  };

  function Account() {
    return (
      <Menu
        id='simple-menu1'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            history.push(`/${currentRole}`);
            handleClose();
          }}
        >
          {/* {currentRole} */}
          {currentRole === smallbusiness ? 'Seller' : shopper}
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(
              ChangeUserRoles(currentRole === shopper ? smallbusiness : shopper)
            );
            handleClose();
          }}
        >
          Switch {currentRole === shopper ? 'Seller' : shopper}
        </MenuItem>
      </Menu>
    );
  }

  function Profile() {
    return (
      <Menu
        id='simple-menu'
        anchorEl={anchorPro}
        keepMounted
        open={Boolean(anchorPro)}
        onClose={handleClose}
      >
        {localStorageData('_id') ? (
          <>
            <MenuItem
              onClick={() => {
                Logout(history.push(`/`));
                handleClose();
              }}
            >
              Log Out
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                history.push(`/Signin`);
                handleClose();
              }}
            >
              Sign in
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push(`/Signup`);
                handleClose();
              }}
            >
              Sign up
            </MenuItem>
          </>
        )}
      </Menu>
    );
  }

  function Sidebar() {
    return (
      <div
        role='presentation'
        onClick={toggleDrawer('left', false)}
        onKeyDown={toggleDrawer('left', false)}
      >
        <List>
          <ListItem
            button
            onClick={() => {
              history.push(`/dealofday`);
            }}
          >
            <ListItemText>
              <span className='text-color-black'> Top Sellers</span>
            </ListItemText>
          </ListItem>

          {localStorageData('_id') ? (
            <>
              <ListItem
                onClick={() => {
                  history.push(`/shopper`);
                }}
              >
                <ListItemText>
                  <span className='text-color-black'>Shopper</span>
                </ListItemText>
              </ListItem>

              <ListItem
                onClick={() => {
                  history.push(`/smallbusiness`);
                }}
              >
                <ListItemText>
                  <span className='text-color-black'>Small Business</span>
                </ListItemText>
              </ListItem>
              {/* <ListItem
                onClick={() => {
                  history.push(`/${currentRole}`);
                }}
              >
                <span className='text-color-black'>
                  {currentRole === smallbusiness ? 'Seller' : shopper}
                </span>
              </ListItem> */}

              {/* <ListItem
                onClick={() => {
                  dispatch(
                    ChangeUserRoles(
                      currentRole === shopper ? smallbusiness : shopper
                    )
                  );
                }}
              >
                <ListItemText>
                  <span className='text-color-black'>
                    {' '}
                    Switch to {currentRole === shopper ? 'Seller' : shopper}
                  </span>
                </ListItemText>
              </ListItem> */}

              <ListItem button onClick={() => Logout(history.push(`/`))}>
                <ListItemText>
                  <span className='text-color-black'>Logout</span>
                </ListItemText>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem
                button
                onClick={() => {
                  toast.error('Login Required');
                }}
              >
                <ListItemText>
                  <span className='text-color-black'>Account</span>
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  history.push(`/Signin`);
                }}
              >
                <ListItemText>
                  <span className='text-color-black'>Shopper</span>
                </ListItemText>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  history.push(`/Signin`);
                }}
              >
                <ListItemText>
                  <span className='text-color-black'>Small Business</span>
                </ListItemText>
              </ListItem>
            </>
          )}

          <ListItem button onClick={() => history.push('/aboutus')}>
            <ListItemText>
              <span className='text-color-black'>About</span>
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => history.push('/howitworks')}>
            <ListItemText>
              <span className='text-color-black'>How It Works</span>
            </ListItemText>
          </ListItem>
        </List>
      </div>
    );
  }

  return (
    <div className='mb-12'>
      <div className='lg-styl shadow bg-black'>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <div className=' w-full logotop   '>
                <button
                  onClick={() => history.push('/')}
                  className='text-white font-bold mt-2 text-lg'
                >
                  ouiiwe
                </button>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className=' mr-4 text-white font-bold mt-4 '>
                <button
                  onClick={() => history.push('/smallbusiness')}
                  className='hovereff'
                >
                  Small Business
                </button>
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className=' mr-4 text-white font-bold mt-4 '>
                <button
                  onClick={() => history.push('/shopper')}
                  className='hovereff'
                >
                  Shopper
                </button>
              </div>
            </Grid>

            <Grid item xs={2}>
              <div className=' mr-4 text-white  font-bold mt-4 '>
                <button
                  onClick={() => history.push('/aboutus')}
                  className='hovereff'
                >
                  About
                </button>
              </div>
            </Grid>
            {/* 
            <Grid item xs={2}>
              <div className=' mr-4 text font-bold mt-4 '>
                <button onClick={handleClick} className='hovereff'>
                  Account
                </button>
              </div>
              <Account />
            </Grid> */}

            <Grid item xs={2}>
              {localStorageData('_id') ? (
                <div className=' mr-4 text-white  font-bold mt-4 '>
                  <button
                    onClick={() => {
                      Logout(history.push(`/`));
                      handleClose();
                    }}
                    className='hovereff'
                  >
                    Log-out
                  </button>
                </div>
              ) : (
                <>
                  <IconButton
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    onClick={handleClickPro}
                  >
                    <PersonIcon style={{ color: 'white' }} />
                  </IconButton>
                  <Profile />
                </>
              )}
            </Grid>

            <Grid item xs={2}>
              <div className=' mr-10 text-black font-semibold  '>
                <CartIcon style={{ color: 'white' }} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className='sm-styl bg-black shadow'>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className=' mt-2 ml-2 text-xl font-semibold  '>
              <React.Fragment>
                <IconButton
                  aria-label='list'
                  style={{ color: 'white' }}
                  onClick={toggleDrawer('left', true)}
                >
                  <ListIcon />
                </IconButton>
                <SwipeableDrawer
                  anchor='left'
                  open={state['left']}
                  onClose={toggleDrawer('left', false)}
                >
                  <Sidebar />
                </SwipeableDrawer>
              </React.Fragment>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className=' grid content-center items-center justify-center -ml-12  h-16  w-full text-xl font-semibold  '>
              <a href='/' className='logoborder text-white font-bold text-xl '>
                ouiiwe
              </a>
            </div>
          </Grid>

          <Grid item xs={2}>
            <div className=' mt-2  text font-semibold float-right '>
              <CartIcon />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Navbar;
