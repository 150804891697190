import React from 'react';
import history from '../../history';
import { localStorageData } from '../../services/auth/localStorageData';
import { toast } from 'react-toastify';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
function BusinessButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='bg-color-bg pb-4 shadow w-full'>
      <Container maxWidth='sm'>
        <div className='flex flex-row grid grid-cols-4 justify-items-center items-center justify-center '>
          <div className='mt-4 mx-8'>
            <button
              className='text-color-gray text-sm underline'
              onClick={() => history.push('/ordersmallbusiness')}
              type='button'
            >
              Orders
            </button>
          </div>

          <div className='mt-4 mx-8'>
            <button
              className='text-color-gray text-sm underline'
              onClick={() => history.push('sell/shopper')}
              type='button'
            >
              Promote
            </button>
          </div>

          {/* <div className='mt-4 '>
            <IconButton
              aria-label='add'
              onClick={() => history.push('sell/shopper')}
            >
              <AddIcon style={{ color: '#858585' }} />
            </IconButton>
        
          </div> */}
          <div className='mt-4 mx-8'>
            <button
              className='text-color-gray text-sm underline'
              type='button'
              onClick={handleClick}
            >
              Sell
            </button>
          </div>

          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => history.push('sell/smallbusiness')}>
              Product
            </MenuItem>
            <MenuItem onClick={() => history.push('sellservices')}>
              Local Service
            </MenuItem>
          </Menu>

          {/* <div className='mt-4 mx-8'>
            <button
              className='text-white underline'
              type='button'
              onClick={() => history.push('sellservices')}
            >
              Sell Service
            </button>
          </div> */}

          <div className='mt-4 mx-8'>
            {localStorageData('_id') ? (
              <>
                <button
                  className='text-color-gray text-sm underline'
                  type='button'
                  onClick={() => history.push('detailsedit')}
                >
                  Details
                </button>
              </>
            ) : (
              <>
                <button
                  className='text-color-gray text-sm underline'
                  type='button'
                  onClick={() => toast.error('Login Required')}
                >
                  Details
                </button>
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default BusinessButton;
