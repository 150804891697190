import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
import Address from 'components/Address';

import ErrorService from 'services/formatError/ErrorService';
import history from './../../history';
import CircularProgress from '@material-ui/core/CircularProgress';
import userServices from 'services/httpService/userAuth/userServices';
import { useMutation } from 'react-query';
function Header() {
  const [lat, setlat] = useState('');
  const [long, setlong] = useState('');

  const [productSearch, setprodutSearch] = useState('');

  const [setaddress] = useState('');

  function changeaddress(value) {}

  const { isLoading, mutate } = useMutation(
    (updateprofile) =>
      userServices.commonPostService('/searchdeals', updateprofile),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        console.log(data.data);

        history.push({
          pathname: '/searchedResult',

          state: { detail: data.data },
        });
      },
    }
  );

  const handleSearch = () => {
    if (lat === '' && productSearch === '') {
      toast.error('Search fields empty');
    } else {
      let data = {
        lat: lat,
        long: long,
        productSearch: productSearch,
      };
      mutate(data);
    }
  };

  return (
    <div>
      <Container>
        <div className='relative   content-center items-center justify-center pb-4'>
          <div className=' text-center justify-items-center'>
            {/* <span className='text-3xl font-semibold color-black text-center ml-2  justify-items-center'>
              ouiiwe
            </span> */}
          </div>

          <div className='color-black text-center mt-1 mb-10 text font-semibold  justify-items-center'>
            {/* Shop Where You Matter. */}
          </div>
          <form
            autocomplete='off'
            className='flex content-center items-center justify-center mt-10'
          >
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={10} xs={10}>
                <div className=''>
                  <input
                    className='	input-styl px-2 py-2 h-12 rounded-3xl'
                    placeholder='Search ouiiwe'
                    type='text'
                    onChange={(e) => {
                      setprodutSearch(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={8} xs={8}>
                <div className=''>
                  <Address
                    label='Address'
                    setaddress={setaddress}
                    setlat={setlat}
                    changeaddress={changeaddress}
                    setlong={setlong}
                  />
                </div>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <div className=''>
                  {isLoading ? (
                    <>
                      <CircularProgress />
                    </>
                  ) : (
                    <button
                      className='btn-styl h-10'
                      type='button'
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}

export default Header;
