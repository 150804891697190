const Category = [
  "Household",
  "Beauty",
  "Accessories",
  "Women",
  "Baby",
  "Toys",
  "Teen",
  "Kids",
  "Men",
  "Crafts",
  "ArtPaintings",
  "Stationery",

  "Local Service",
  "Pet",
  "Other",
];
export default Category;
