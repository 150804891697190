import React from 'react';
import ProfileHeader from 'components/ProfileHeader';
import { s3BucketUrl } from 'config/config';
import { localStorageData } from 'services/auth/localStorageData';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
function Header() {
  const location = useLocation();
  const { businessName, currentRole } = useSelector((state) => state.userRole);

  // console.log(businessName);
  return (
    <>
      <ProfileHeader
        name={
          localStorageData('_id')
            ? currentRole == 'smallbusiness'
              ? businessName
              : localStorageData('username')
            : ''
        }
        upload={true}
        search={true}
        follow={false}
        // img={localStorageData('pic')}
        img={
          localStorageData('_id')
            ? s3BucketUrl + localStorageData('pic')
            : 'https://ui-avatars.com/api/?name=John+Doe'
        }
      />
    </>
  );
}

export default Header;
