import React from 'react';
import Grid from '@material-ui/core/Grid';

import Container from '@material-ui/core/Container';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import { userSignup } from '../actions/userAction/userActions';
import CircularProgress from '@material-ui/core/CircularProgress';
function Signup() {
  const dispatch = useDispatch();
  const { error, isLoading, success } = useSelector(
    (state) => state.userInfo.userRegister
  );

  // const validate = values => {
  //   const errors = {}

  //   if (!values.email) {
  //     errors.email = 'Required'
  //   } else if (values.email.length < 4) {
  //     errors.email = 'Must be 5 characters or more'
  //   }

  //   return errors
  // }

  const formik = useFormik({
    initialValues: {
      email: '',
      fname: '',
      lname: '',
      address: '',
      contactNo: '',
      pass: '',
      username: '',
      cpass: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Required'),
      fname: Yup.string()
        .min(3, 'Must be more than 3 characters')
        .required('Required'),
      lname: Yup.string()
        .min(3, 'Must be more than 3 characters')
        .required('Required'),

      username: Yup.string()
        .min(3, 'Must be more than 4 characters')
        .required('Required'),
      address: Yup.string().required('Required'),
      contactNo: Yup.string().required('Required'),
      pass: Yup.string()
        .min(8, 'Must be more than 8 characters')
        .required('Required'),
      cpass: Yup.string().oneOf(
        [Yup.ref('pass'), null],
        'Passwords must match'
      ),
    }),
    onSubmit: async (values) => {
      // let res = await dispatch(userSignup(values));
      // alert(res)

      dispatch(userSignup(values))
        .then(() => {
          /// alert("done")
        })
        .catch(() => {});
    },
  });

  return (
    <div>
      <Container maxWidth='sm'>
        <form className='flex mt-20' onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className='text-xl antialiased center-styl font-bold items-center'>
                Sign Up
              </div>
              <div className='mt-4'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='Name'
                >
                  First Name
                </label>
                <input
                  type='text'
                  name='fname'
                  id='fname'
                  className='input-styl px-2 py-2 h-10'
                  placeholder='First Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fname}
                />
                {formik.touched.fname && formik.errors.fname ? (
                  <div className='text-red-600'>{formik.errors.fname}</div>
                ) : null}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='mt-4'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='Name'
                >
                  Last Name
                </label>
                <input
                  type='text'
                  name='lname'
                  id='lname'
                  className='input-styl px-2 py-2 h-10'
                  placeholder='Last Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lname}
                />
                {formik.touched.lname && formik.errors.lname ? (
                  <div className='text-red-600'>{formik.errors.lname}</div>
                ) : null}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='mt-4'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='Name'
                >
                  User Name
                </label>
                <input
                  type='text'
                  name='username'
                  id='username'
                  className='input-styl px-2 py-2 h-10'
                  placeholder='User Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className='text-red-600'>{formik.errors.username}</div>
                ) : null}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='mt-4'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='email'
                >
                  Email
                </label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  className='input-styl px-2 py-2 h-10'
                  placeholder='Email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='text-red-600'>{formik.errors.email}</div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className='mt-4'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='email'
                >
                  Address
                </label>
                <input
                  type='text'
                  name='address'
                  id='address'
                  value={formik.values.address}
                  onChange={(e) =>
                    formik.setFieldValue('address', e.target.value)
                  }
                  className='input-styl px-2 py-2 h-10'
                  placeholder='Address'
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className='text-red-600 text-xs'>
                    {formik.errors.address}
                  </div>
                ) : null}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='mt-4'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='email'
                >
                  Phone Number
                </label>
                <input
                  type='text'
                  name='contactNo'
                  id='contactNo'
                  value={formik.values.contactNo}
                  onChange={(e) =>
                    formik.setFieldValue('contactNo', e.target.value)
                  }
                  className='input-styl px-2 py-2 h-10'
                  placeholder='Phone Number'
                />
                {formik.touched.contactNo && formik.errors.contactNo ? (
                  <div className='text-red-600 text-xs'>
                    {formik.errors.contactNo}
                  </div>
                ) : null}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='mt-4'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='Name'
                >
                  Password
                </label>
                <input
                  type='password'
                  name='pass'
                  id='pass'
                  className='input-styl px-2 py-2 h-10'
                  placeholder='Password'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pass}
                />
                {formik.touched.pass && formik.errors.pass ? (
                  <div className='text-red-600'>{formik.errors.pass}</div>
                ) : null}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='mt-4'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='Name'
                >
                  Confirm Password
                </label>
                <input
                  type='password'
                  name='cpass'
                  id='cpass'
                  className='input-styl px-2 py-2 h-10'
                  placeholder='Confirm Password'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cpass}
                />
                {formik.touched.cpass && formik.errors.cpass ? (
                  <div className='text-red-600'>{formik.errors.cpass}</div>
                ) : null}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='flex flex-row  justify-items-center items-center justify-center'>
                <div className='mt-2 mx-8'>
                  {isLoading == true ? (
                    <CircularProgress />
                  ) : (
                    <button className='btn-styl' type='submit'>
                      Sign Up
                    </button>
                  )}
                </div>
                <div className='mt-2 mx-8'></div>
              </div>
            </Grid>
            {error && <Alert severity='error'>{error}</Alert>}
            {success && <Alert severity='success'>{success}</Alert>}
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default Signup;
