import React, { useState } from 'react';

import Container from '@material-ui/core/Container';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useMutation } from 'react-query';
import userServices from '../../services/httpService/userAuth/userServices';
import ErrorService from '../../services/formatError/ErrorService';

function ForgetPassword() {
  const [successData, setsuccessData] = useState('');
  const [errorMsg, seterrorMsg] = useState('');

  const {
    isError,

    isLoading,

    isSuccess,
    mutate,
  } = useMutation(
    (applyForPass) => userServices.applyForForgetPass(applyForPass),
    {
      onError: (error) => {
        seterrorMsg(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setsuccessData(data.data);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      mutate(values);
    },
  });

  return (
    <div>
      <Container>
        <form
          className=' relative w-1/2 mt-4 mb-3 mt-16'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-xl antialiased  font-bold items-center'>
            Forget Password
          </div>

          <div className='my-10'>
            <label
              className='block uppercase text-gray-700 text-xs font-bold mb-2'
              htmlFor='Name'
            >
              Email
            </label>
            <input
              type='email'
              id='email'
              name='email'
              className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded-full text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-2 border-gray-600 rounded-sm'
              placeholder='Email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className='text-red-600'>{formik.errors.email}</div>
            ) : null}
          </div>

          <div className='my-10'>
            <div className='flex flex-row  justify-items-center items-center justify-center'>
              <div className='mt-2 mx-8'>
                {isLoading == true ? (
                  <CircularProgress />
                ) : (
                  <button
                    className='bg-white  uppercase orgcolor font-bold shadow-2xl btn-shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150'
                    type='submit'
                  >
                    Submit
                  </button>
                )}
              </div>
              <div className='mt-2 mx-8'>
                {/* <button
                className='bg-white  uppercase orgcolor font-bold shadow-2xl btn-shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150'
                type='button'
              >
                Sign In
              </button> */}
              </div>
            </div>
          </div>
        </form>
        {/* {isError && <Alert severity="error">{isError}</Alert>} */}
        {isError && <Alert severity='error'>{errorMsg}</Alert>}
        {isSuccess && <Alert severity='success'> {successData}</Alert>}
      </Container>
    </div>
  );
}

export default ForgetPassword;
