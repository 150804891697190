import React from "react";
import { s3BucketUrl, smallbusiness } from "config/config";
import history from "./../../history";

function ReviewCard(props) {
  const data = props.data;
  console.log("it come forom review-------------", data);
  return (
    <>
      {data.map((s, i) => (
        <div className='card1'>
          <div className='rounded-md border my-4  border-light-blue-500 border-opacity-100 overflow-hidden w-72'>
            <div className='each-fade'>
              <a
                className='p-2 flex'
                onClick={() => {
                  history.push(`/shopperview/${s.customer._id}`);
                }}
              >
                <img
                  src={s3BucketUrl + s.customer.pic}
                  // alt='Loading'
                  className='w-10 h-10 rounded-full object-cover'
                />
                <span className='mt-2 mx-2'>{s.customer.username}</span>
              </a>
              <a
                onClick={() => {
                  history.push(`/visitprofile/${s.shop.users}`);
                }}
                className='orgcolor  flex grid content-center items-center justify-center mb-2'
              >
                {s.shop.businessName}
              </a>
              <div className='image-container'>
                {s.pic != "" ? (
                  <img
                    src={s3BucketUrl + s.pic}
                    alt='Loading'
                    className='flex items-end justify-end h-80  w-full   bg-cover bg-center hoverzoom'
                  />
                ) : (
                  <div className='h-2' />
                )}
              </div>
            </div>

            <div className='px-3 py-3'>
              <div className='text-center text-sm  '>{s.review}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default ReviewCard;
