import React from 'react';

import history from '../../history';
import { toast } from 'react-toastify';
import Container from '@material-ui/core/Container';
import { localStorageData } from '../../services/auth/localStorageData';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

function ShopperButton() {
  return (
    <div>
      <div className='bg-color-bg shadow'>
        <Container maxWidth='sm'>
          <div className='flex flex-row  grid grid-cols-3 justify-items-center items-center justify-center '>
            <div className=' my-4 mx-8'>
              {localStorageData('_id') ? (
                <>
                  <button
                    className=' underline text-color-gray text-sm'
                    type='button'
                    onClick={() => history.push('detailsedit')}
                  >
                    Details
                  </button>
                </>
              ) : (
                <>
                  <button
                    className='text-color-gray text-sm underline '
                    type='button'
                    onClick={() => toast.error('Login Required')}
                  >
                    Details
                  </button>
                </>
              )}
            </div>

            {/* <div className='mt-4'>
              <IconButton
                aria-label='add'
                onClick={() => history.push('sell/shopper')}
              >
                <AddIcon fontSize='large' style={{ color: 'white' }} />
              </IconButton>
            </div> */}
            <div className='my-4 '>
              <button
                className='text-color-gray text-sm underline '
                type='button'
                onClick={() => history.push('/display')}
              >
                Display
              </button>
              &nbsp;
              <Tooltip
                leaveTouchDelay={30}
                className='text-black'
                enterTouchDelay={5}
                title='The Display feature allows you as a shopper to showcase your purchases while leaving a review detailing your experience with local sellers. Show it off and leave a review. '
                placement='top'
                style={{ marginBottom: 15 }}
              >
                <InfoIcon fontSize='small' />
              </Tooltip>
            </div>
            <div className='my-4 mx-8'>
              <button
                onClick={() => history.push('/ordersshopper')}
                className='text-color-gray text-sm underline '
                type='button'
              >
                Orders
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ShopperButton;
