import Maincard from '../components/Card/Maincard';
export function filterProductBycategory(filterTag, data) {
  let filteredData = data.filter((values) => values._id === filterTag);
  // eslint-disable-next-line
  if (filteredData != '' && filteredData[0].hasOwnProperty('_id')) {
    //// console.log(filteredData[0].docs);

    return (
      <Maincard
        seemore={true}
        category={filterTag}
        cardData={filteredData[0].docs}
        swipe={false}
      />
    );
    // return filteredData[0].docs;
  }
}

export function filterProductByDeal(filterTag, data) {
  let filteredData = data.filter((values) => values._id === filterTag);
  // eslint-disable-next-line
  if (filteredData != '' && filteredData[0].hasOwnProperty('_id')) {
    //// console.log(filteredData[0].docs);
    console.log(filteredData[0].docs);

    let arr = [];
    arr.push(filteredData[0].docs[0]);

    return (
      <Maincard
        seemore={false}
        category={filterTag}
        cardData={arr}
        swipe={false}
      />
    );
    // return filteredData[0].docs;
  }
}
