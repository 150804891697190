import React from 'react';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { CartAction } from 'client/actions/CartAction/CartActions';
function Options(props) {
  const dispatch = useDispatch();

  const { smallbusinessPass, businessName, currentRole } = useSelector(
    (state) => state.userRole
  );

  let uniqesizes = [
    ...new Set(
      props.data.sizes.map((item) => {
        return item.size;
      })
    ),
  ];

  console.log('uniquw sixes--------', uniqesizes);

  let availability = [
    // ...new Set(
    //   props.data.availability.map((item) => {
    //     // item.timing.map((item1) => {
    //     //   return sitem.size;
    //     // })
    //     return item.timing;
    //   })
    // ),
  ];

  for (let i = 0; i < props.data.availability.length; i++) {
    for (let j = 0; j < props.data.availability[i].timing.length; j++) {
      availability.push({
        startTime: props.data.availability[i].timing[j].startTime,
        endTime: props.data.availability[i].timing[j].endTime,
        datecounter: i,
        timingcounter: j,
      });
    }
  }
  console.log('availability', availability);

  const [totalPrice, settotalPrice] = React.useState(0);

  const [selectedSize, setselectedSize] = React.useState('');

  const [selectedAvailability, setselectedAvailability] = React.useState();

  const [colorList, setcolorList] = React.useState([]);

  const [selectedColor, setselectedColor] = React.useState('');

  const [selectedQuantity, setselectedQuantity] = React.useState('');

  const quantityOptions = [1, 2, 3, 4, 5];

  // function changeQuantity(qty, data) {
  //   /// alert(qty + data.nowPrice);

  //   /// data.nowPrice = 100;
  //   settotalPrice(qty * data.nowPrice);
  // }

  function AddtoCart() {
    if (currentRole != 'smallbusiness') {
      let actualQTY;

      props.data.sizes.map((item) => {
        if (item.size == selectedSize && item.color == selectedColor)
          actualQTY = item.qty;
      });

      //// actualQTY = actualQTY.replace(/\D/g, "");

      console.log(actualQTY);
      /// alert(actualQTY);

      if (actualQTY < selectedQuantity) {
        toast.error(`Deal out of stock only ${actualQTY} item left`);
      } else {
        // console.log('total Price' + totalPrice);
        // console.log(selectedSize);
        // console.log(selectedColor);
        // console.log(props.data);

        if (selectedQuantity === '' && props.data.uplodertype != 'services') {
          toast.error('Please select color , size and quantity first');
        } else {
          console.log('-----------------------------', props.data.users);

          let cartObj = {
            shopId: props.data.users._id,
            shopImg: props.data.users.pic,
            productId: props.data._id,
            quantity:
              props.data.uplodertype == 'services' ? 1 : selectedQuantity,
            color: selectedColor,
            size: selectedSize,
            nowPrice: props.data.nowPrice,
            shopName: props.data.shopName,
            postageFee: props.data.postageFee,
            data: props.data,
            type: props.data.uplodertype == 'services' ? 'services' : 'other',
            availability: selectedAvailability,
          };

          dispatch(CartAction(cartObj));
          toast.success('Added to Cart');
        }
      }
    } else {
      toast.error('Please switch to shopper account');
    }
  }

  console.log(props.data.sizes);

  return (
    <div className=' my-4 mx-2'>
      <Grid container spacing={1}>
        {props.data.uplodertype == 'services' ? (
          <>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              {/* <label for='qty'>QTY: </label> */}

              <select
                name='qty'
                id='qty'
                className='input-styl w-full h-8'
                onChange={(e) => {
                  setselectedAvailability(JSON.parse(e.target.value));

                  settotalPrice(parseInt(props.data.nowPrice));
                  // let newarr = [];
                  // props.data.sizes.map((item) => {
                  //   if (item.size == e.target.value)
                  //     return newarr.push(item.color);
                  // });
                  // setcolorList(newarr);
                  // setselectedSize(e.target.value);
                }}
              >
                <option value='' disabled selected>
                  Availability
                </option>

                {availability.map((item, i) => (
                  <>
                    <option value={JSON.stringify(item)}>
                      {moment(item.startTime).format('MM/DD/YYYY h:mm A ') +
                        '- ' +
                        moment(item.endTime).format(' h:mm A ')}
                    </option>
                  </>
                ))}
              </select>
            </Grid>
          </>
        ) : (
          <>
            <Grid item lg={4} md={3} sm={3} xs={3}>
              {/* <label for='qty'>QTY: </label> */}

              <select
                name='qty'
                id='qty'
                className='input-styl w-4'
                onChange={(e) => {
                  let newarr = [];

                  props.data.sizes.map((item) => {
                    if (item.size == e.target.value)
                      return newarr.push(item.color);
                  });

                  console.log('new arr', newarr);

                  setcolorList(newarr);

                  setselectedSize(e.target.value);
                }}
              >
                <option value='' disabled selected>
                  Size
                </option>
                {uniqesizes.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </Grid>

            <Grid item lg={4} md={3} sm={3} xs={3}>
              {/* <label for='qty'>QTY: </label> */}

              <select
                name='Color'
                id='Color'
                className='input-styl w-4'
                onChange={(e) => {
                  setselectedColor(e.target.value);

                  //// settotalPrice(parseInt(e.target.value) * props.data.nowPrice);
                  ///setselectedQuantity(parseInt(e.target.value));
                }}
              >
                <option value='' disabled selected>
                  Color
                </option>
                {colorList.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </Grid>

            <Grid item lg={4} md={3} sm={3} xs={3}>
              {/* <label for='qty'>QTY: </label> */}

              <select
                name='Color'
                id='Color'
                className='input-styl w-4'
                onChange={(e) => {
                  settotalPrice(parseInt(e.target.value) * props.data.nowPrice);
                  setselectedQuantity(parseInt(e.target.value));
                }}
              >
                <option value='' disabled selected>
                  Qty
                </option>
                {quantityOptions.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </Grid>
          </>
        )}

        {/* <Grid item lg={4} md={4} sm={4} xs={4}>
          <select
            name="colors"
            id="colors"
            className="input-styl"
            onChange={(e) => setselectedColor(e.target.value)}
          >
            <option value="N/A" selected>
              Colors
            </option>
            {props.data.color.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select> */}
        {/* </Grid> */}

        {/* <Grid item lg={4} md={4} sm={4} xs={4}>
          <select
            name='size'
            id='size'
            className='input-styl'
            onChange={(e) => setselectedSize(e.target.value)}
          >
            <option value='N/A' selected>
              Size
            </option>
            {props.data.sizes.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </Grid> */}

        <Grid item lg={4} md={4} sm={4} xs={4}>
          <div className='text-gray-400 my-2 mt-4'>
            cost: $<span className='text-red-600'>{totalPrice}</span>{' '}
          </div>
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={8}>
          <div className='my-2 mt-4 float-right'>
            <button
              className='sell-btn'
              type='button'
              onClick={() => AddtoCart()}
            >
              Add To Cart
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Options;
