import React, { useState } from 'react';
import ProfileHeader from 'components/ProfileHeader';
import Container from '@material-ui/core/Container';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import userServices from 'services/httpService/userAuth/userServices';
import Maincard from 'components/Card/Maincard';
import { s3BucketUrl } from 'config/config';
import Skelton from 'components/Card/Skelton';
import ReviewCard from 'components/Card/ReviewCard';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
function VisitProfile() {
  const params = useParams();
  const [deals, setdeals] = useState([]);
  const [review, setReview] = useState([]);

  const [nameofuploder, setnameofuploder] = useState('');
  const [picuploder, setpicofuploder] = useState('');
  const [story, setStory] = useState('');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ShopperDeals = useQuery(
    'userStatus',
    () => userServices.commonGetService(`/fetchbyvistorid/${params.id}`),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {},
      onSuccess: (data) => {
        setnameofuploder(data.data[0].sellers.businessName);
        console.log('=======================');
        console.log(data.data);
        setdeals(data.data[0].deals.reverse());
        setpicofuploder(data.data[0].pic);
        setStory(data.data[0].sellers.story);
      },
    }
  );

  const userReviews = useQuery(
    'userReviews',
    () => userServices.commonGetService(`/fetchReviewsBusiness/${params.id}`),

    {
      refetchOnWindowFocus: false,
      onError: (error) => {},
      onSuccess: (data) => {
        setReview(data.data);
      },
    }
  );

  return (
    <div>
      <ProfileHeader
        name={nameofuploder}
        vistorid={params.id}
        img={s3BucketUrl + picuploder}
        // img={
        //   params.id
        //     ? s3BucketUrl + picuploder
        //     : 'https://ui-avatars.com/api/?name=John+Doe'
        // }
        upload={false}
        search={false}
        follow={true}
      />
      <br />
      <Container maxWidth='lg'>
        <button
          className='underline text-black font-semibold p-4 '
          onClick={handleClickOpen}
        >
          Story
        </button>

        <div className='row1'>
          {ShopperDeals.isLoading === false ? (
            <Maincard cardData={deals} />
          ) : (
            <div className='row1 '>
              <Skelton />
              <Skelton />
              <Skelton />
            </div>
          )}
        </div>
        <div className='text-lg font-semibold my-4'>Reviews</div>
        <div className='row1'>
          {userReviews.isLoading === false ? (
            <>
              <ReviewCard data={review} />
            </>
          ) : (
            <div className='row1 '>
              <Skelton />
              <Skelton />
              <Skelton />
            </div>
          )}
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Business’ story'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <div className='text-center my-10'>{story}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}

export default VisitProfile;
