import React from 'react';
import Container from '@material-ui/core/Container';
function Footer() {
  return (
    <div className='container mx-auto'>
      <footer className='footer '>
        <Container>
          <div className='my-10'>
            <div className='text-black  text-xs justify-start content-center text-center '>
              Discover, shop, and support small businesses.
              <br /> Contact us: ouiiwe@ouiiwe.onmicrosoft.com <br />
              ABN: 35903724003
            </div>
            <div className='mt-1 justify-start content-center text-center '>
              <a
                className='bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
                type='button'
                href='https://www.facebook.com/latestlocaldealz'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fab fa-facebook-square mt-3'></i>
              </a>
              <a
                className='bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
                type='button'
                href='https://www.instagram.com/latestlocaldealz_/'
                target='_blank'
                rel='noreferrer'
              >
                <i className='fab fa-instagram mt-3'></i>
              </a>
            </div>

            <div className='mt-4 justify-start content-center text-center '>
              <a
                href='/privacypolicy'
                className='text-sm text-[#824aac]'
                target='_blank'
              >
                Privacy Policy
              </a>
              <a
                href='/termcondition'
                className='text-sm mx-2 text-[#824aac]'
                target='_blank'
              >
                Terms & conditions
              </a>
              <a
                href='/howitworks'
                className='text-sm mx-2 text-[#824aac]'
                target='_blank'
              >
                How it Works
              </a>
            </div>
          </div>
        </Container>
      </footer>
    </div>
  );
}

export default Footer;
