import React, { useState, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../actions/userAction/userActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import history from './../../history';
function Signin() {
  const [success, setsuccess] = useState(false);
  const dispatch = useDispatch();

  const { error, isLoading } = useSelector((state) => state.userInfo.userlogin);

  const formik = useFormik({
    initialValues: {
      pass: '',
      username: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .min(3, 'Must be more than 4 characters')
        .required('Required'),
      pass: Yup.string()
        .min(8, 'Must be more than 8 characters')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      // let res = await dispatch(userSignup(values));
      // alert(res)

      dispatch(login(values))
        .then(() => {
          ////  alert("succfully login")

          setsuccess(true);
          history.push('/');
        })
        .catch(() => {});
    },
  });

  return (
    <div>
      <Container maxWidth='sm'>
        <form className=' w-full relative mt-20' onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className='text-xl antialiased  font-bold items-center'>
                Sign In
              </div>

              <div className='mt-4'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='Name'
                >
                  Email/ Username
                </label>
                <input
                  type='text'
                  className='input-styl px-2 py-2 h-10'
                  placeholder=' Email/ Username'
                  id='username'
                  name='username'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className='text-red-600 text-sm'>
                    {formik.errors.username}
                  </div>
                ) : null}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='mt-2'>
                <label
                  className='block uppercase text-gray-700 text-xs font-bold mb-2'
                  htmlFor='Name'
                >
                  Password
                </label>
                <input
                  type='password'
                  name='pass'
                  id='pass'
                  className='input-styl px-2 py-2 h-10'
                  placeholder='Password'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pass}
                />
                {formik.touched.pass && formik.errors.pass ? (
                  <div className='text-red-600 text-sm'>
                    {formik.errors.pass}
                  </div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className='mt-2 mx-8 w-full'>
                {isLoading == true ? (
                  <CircularProgress />
                ) : (
                  <button className='btn-styl' type='submit'>
                    Sign In
                  </button>
                )}
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className='mt-2 mx-8 w-full'>
                <a className='btn-styl' href='/signup'>
                  Sign up
                </a>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className='mt-2 mx-8 w-full'>
                <a className='text-red-600' href='/forgetpass'>
                  Forget Password
                </a>
              </div>
            </Grid>
          </Grid>
        </form>
        {error && <Alert severity='error'>{error}</Alert>}
        {success && <Alert severity='success'> Successfully Login</Alert>}
      </Container>
    </div>
  );
}

export default Signin;
